import { Heading, Box } from '@chakra-ui/react';
import React from 'react';
import PARAGRAPH_MAPPING from '../../../config/paragraph-mapping';
import { Page as TPage, PageFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import Wrapper from '../Wrapper';

export interface PageProps {
  data: PageFragmentFragment;
}

const Page: React.FC<PageProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Wrapper typename={data.__typename} activeCategory={data.category || null}>
      <Box className="NodePage">
        {data.showTitle && (
          <Container paddingTop={12}>
            <Heading as="h1">{data.title}</Heading>
          </Container>
        )}

        <Box className="ContentWrapper">
          {data.content?.map((data) => {
            if (PARAGRAPH_MAPPING[data.__typename]) {
              const Component = PARAGRAPH_MAPPING[data.__typename];
              return <Component key={data.id} data={data} />;
            }

            return null;
          })}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Page;
